<template>
  <div class="container">
    <div>
      <h1>{{ title }}</h1>
      <div id="flipdown" class="flipdown"></div>
    </div>
    <div class="bg"></div>
  </div>
</template>

<script>
// import { default as FlipDown } from "flipdown/dist/flipdown.min.js";
import FlipDown from "flipdown";
import { getUnixTime } from "date-fns";
import { state, getters } from "@/store.js";

export default {
  name: "App",
  data() {
    return {
      toggleScaleFade: false,
      timerId: null,
      timerInterval: 1000,
      /**
       * days: 22
       * hours: 18
       * minutes: 43
       * months: 10
       * seconds: 53
       * years: 6
       */
      diff: {},
      percentage: 0.0001,
      title: state.title
    };
  },
  mounted() {
    // this.start();
    new FlipDown(getUnixTime(state.dueDateTime), {
      theme: "light",
      headings: ["日", "時", "分", "秒"]
    }).start();
  },
  methods: {
    start() {
      this.timerId = setTimeout(() => {
        this.diff = getters.diff();
        const rate = getters.diffInSeconds() / state.totalSeconds;
        this.percentage = (1 - rate) * 100;
        console.log(this.percentage);
        this.start();
      }, this.timerInterval);
    }
  }
};
</script>

<style>
body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.container {
  height: auto;
  margin: auto;
}
.bg {
  z-index: -1000;
  background-color: #30312e;
  background-image: url("bg.jpeg");
  filter: blur(8px);
  -webkit-filter: blur(8px);

  height: 100vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
/* fix flipdown */
.flipdown {
  width: auto;
}
.rotor-group-heading::before {
  color: #111111 !important;
}
.rotor-group:nth-child(4) {
  display: none;
}
.rotor-group:nth-child(3):after,
.rotor-group:nth-child(3):before {
  display: none;
}
</style>
