import { reactive } from 'vue'
import { addYears, intervalToDuration, differenceInSeconds } from 'date-fns'

const config = {
  beginDateTime: new Date('2021-09-07T04:21-0700'),
  waitForYear: 7
}
config.dueDateTime = addYears(config.beginDateTime, config.waitForYear)
config.totalSeconds = differenceInSeconds(
  config.dueDateTime,
  config.beginDateTime,
)
config.title = `分手 ${config.waitForYear} 年內要追回他，尚餘：`

const state = reactive(config)

const getters = {
  diff() {
    return intervalToDuration({
      start: Date.now(),
      end: config.dueDateTime
    })
  },
  diffInSeconds() {
    return differenceInSeconds(
      config.dueDateTime,
      Date.now(),
    )
  }
}

const mutations = {
}

const actions = {
}

export { state, getters, mutations, actions }
